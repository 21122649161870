import axios from 'axios';
// config
import { API_BASE_URL } from '../config';

// ----------------------------------------------------------------------

const params = new URLSearchParams(window.location.href.split('?')[1]);
const authToken = params.get('auth_token');

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (authToken)
      config.headers.Authorization = `LfWcA ${authToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
