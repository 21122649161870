
const dayTimeSalutation = () => {
  const now = new Date();
  const hours = now.getHours();
  if (hours < 12) return "Bom dia!";
  if (hours < 18) return "Boa tarde!";
  return "Boa noite!";
}

export default dayTimeSalutation;
